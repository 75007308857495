<!--
 * @Descripttion:
 * @version:
 * @Author: 李波
 * @Date: 2020-01-09 17:12:51
 * @LastEditors: 王广婷
 * @LastEditTime: 2020-04-27 10:53:31
 -->
<template>
  <div class="content">
    <div class="article-type-container tochscroll">
      <div class="article-type">
        <md-tab-bar v-model="current"
                    :items="labellist"
                    :maxLength="5"
                    @change="tabluck"
                    class="tabbar" />
      </div>
    </div>
    <div class="productcontent"
         style="padding:1rem;"
         v-if="iscpxq">
      <div id="jumpshow"></div>
    </div>
    <div v-if="iselck">
      <div class="menu_box"
           v-for="(item,index) in ziliao"
           :key="index">
        <div class="title">
          <span class="blod"></span>
          <strong class="fenlei">{{item.name}}</strong>
        </div>
        <div class="con_menu"
             v-for="(itemaa,index) in item.list"
             :key="index">
          <div class="con-item">
            <h4 class="con_menu-title"
                @click="clickmore(itemaa)">{{itemaa.filename}}</h4>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {
  choseSupplyList,
  showList,
  choseList,
  selectcpzl,
  comshowaa
} from '@/api/agent/agent/index'
import { getStorage } from '@/lib/util'
import { thistle } from 'color-name'
import loadMore from '@/mixins/loadmore'// 下拉加载的
import initWebSocket from '@/mixins/websock'
import { wechatshare } from '@/lib/wechat_share'
import config from '@/config'
import { DoRecord } from '@/api/abt/customerOperation/common/index'
// import { comshowaa } from '@/api/abt/customerOperation/visitingcard/index'
const { redirect_uri } = config
export default {
  mixins: [loadMore, initWebSocket], // 下拉加载的
  data () {
    return {
      ziliao: [],
      iscpxq: false,
      iselck: false,
      loadflag: false,
      listFinished: false,
      wzlist: [],
      listpageNo: 1,
      labellist: [
        {
          label: '产品详情',
          name: 'cpxq'
        },
        {
          label: '产品资料',
          name: 'cpzl'
        }
      ],
      current: 'cpxq',
      ossurl: '',
      jumpUrl: '',
      info: {}
    }
  },
  components: {

  },
  created () {
    if (this.$route.query.title) {
      document.title = this.$route.query.title
    }
    this.user = getStorage('u_s', {})
    this.ossurl = getStorage('ossurl', '')
    this.jumpUrl = this.$route.query.jumppath
    this.sno = this.$route.query.sclasscode ? this.$route.query.sclasscode : this.$route.query.prodcode
    this.empno = this.$route.query.empno ? this.$route.query.empno : this.user.empno
    this.rytype = this.$route.query.rytype ? this.$route.query.rytype : this.user.rytype
    this.suserid = this.$route.query.suserid ? this.$route.query.suserid : this.user.userid
    this.getclassinfo()
  },
  mounted () {
    this.jump()
  },
  methods: {
    clickmore (val) {
      location.href = this.ossurl + val.filehttp
    },
    tabluck (item, index, prevIndex) {
      // this.stagcode = item.name
      // if (item.name == 'new') {
      //   this.stagcode = ''
      // }
      if (item.name == 'cpzl') {
        selectcpzl({ prodcode: this.sno }).then(res => {
          this.ziliao = res.data.data
        })
        this.iselck = true
        this.iscpxq = false
      }
      if (item.name == 'cpxq') {
        this.iselck = false
        this.iscpxq = true
      }
      this.listpageNo = 1
      this.wzlist = []
      this.listFinished = false
      this.loadflag = false
      // this.$refs.listscrollView.finishLoadMore()
      // console.log('--------------切换中--------------')
      // console.log('切换时总页数' + this.listtotalPage)
      // console.log('切换时当前页' + this.listpageNo)
      // console.log('切换时加载状态' + this.loadflag)
      // console.log('切换时是否停止加载' + this.listFinished)
      // this.getData()
    },
    jump () {
      $('#jumpshow').load(this.ossurl + this.jumpUrl)
    },
    // 微信分享
    wxshare (val) {
      let fxstr = {
        suserid: this.suserid,
        empno: this.empno,
        sno: this.sno,
        rytype: this.rytype,
        otype: '2',
        btagcode: '7'
      }
      let info = getStorage('u_s', {})
      let shareurl = `${redirect_uri}/productShow?jumppath=${this.jumpUrl}&sclasscode=${this.sno}&title=${this.$route.meta.title}&&recordtype=7&suserid=${this.user.userid}&empno=${this.empno}&rytype=${this.rytype}&sharetype=00`
      wechatshare(
        val.classname,
        val.prodesc,
        this.ossurl + val.iconhttp,
        encodeURI(shareurl),
        redirect_uri,
        fxstr
      )
    },
    getclassinfo () {
      comshowaa({ sclasscode: this.$route.query.sclasscode || this.$route.query.prodcode }).then(res => {
        this.info = res.data.data
        this.behaviorRecord()
        this.wxshare(this.info)
      })
    },
    // 调行为记录接口
    behaviorRecord () {
      let data = {
        suserid: this.suserid,
        empno: this.empno,
        sno: this.sno,
        otype: '1',
        btagcode: '7',
        rytype: this.rytype
      }
      DoRecord(data).then(res => {
        let id = res.data.data.id
        let comid = this.user.comid
        this.initWebSocket(id, comid) // 开启websocket连接
      })
    }
  }
}
</script>
<style scoped lang="stylus">
@import './index.styl';

.menu_box {
  padding: 40px 25px;
  margin-bottom: 30px;
  background-color: bg-color;
}

.blod {
  height: 40px;
  width: 12px;
  background: rgb(187, 26, 32);
  display: inline-block;
  vertical-align: -6px;
  margin-right: 10px;
}

.fenlei {
  font-weight: 550;
  font-size: 0.45rem;
}

.con_menu {
  width: 100%;
  margin-top: 30px;
  flex-wrap: wrap;

  .con_menu-title {
    width: 6.8rem;
    margin-top: 30px;
    // margin-left: 5%;
    font-weight: 500;
    color: #509BDE;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .con-item {
    width: 98%;
    height: 0.6rem;
    margin-top: 15px;
    margin-right: 1%;
    margin-left: 1%;
    // position: relative;
    // background-color: #eee;
    // border-radius: 5px;
  }
}
</style>
